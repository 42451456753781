<template>
    <div class="areaSelect">
      <el-select v-if="showOption.country===undefined || showOption.country!==false" :class="{ inputReadonly: readonly }" :required="required" :disabled="readonly" filterable v-model="country" @change="changeCountry" placeholder="请选择">
            <el-option
                v-for="item in countryData"
                :key="item.value"
                :label="item.label"
                :value="item.label"
                :option="item"
                >
            </el-option>
        </el-select>
        <el-select ref="prv" v-if="showOption.province===undefined || showOption.province!==false" :class="{ inputReadonly: readonly }" :required="required" :disabled="readonly" filterable v-model="province" @change="changeProvince" placeholder="请选择">
            <el-option
                v-for="item in regionData"
                :key="item.value"
                :label="item.label"
                :value="item.label">
            </el-option>
        </el-select>
        <el-select v-if="showOption.city===undefined || showOption.city!==false" :class="{ inputReadonly: readonly }" :required="required" :disabled="readonly" filterable v-model="city" @change="changeCity" placeholder="请选择">
            <el-option
                v-for="item in cityData"
                :key="item.value"
                :label="item.label"
                :value="item.label">
            </el-option>
        </el-select>
        <el-select v-if="showOption.district===undefined || showOption.district!==false" :class="{ inputReadonly: readonly }" :required="districtRequired" :disabled="readonly" filterable v-model="district" @change="changeDistrict" placeholder="请选择">
            <el-option
                v-for="item in districtData"
                :key="item.value"
                :label="item.label"
                :value="item.label">
            </el-option>
        </el-select>
    </div>
</template>

<script>
import { regionData } from 'element-china-area-data'

export default {
  name: 'AreaSelector',
  props: ['addrIndex', 'readonly', 'required', 'provincedData', 'citydData', 'districtdData', 'countrydData', 'option'],
  data: function () {
    var tcitydata = []
    var districtdData = []
    for (var node in this.regionData) {
      if (this.regionData[node].label === this.provincedData) {
        tcitydata = this.regionData[node].children
      }
    }
    for (var nodes in tcitydata) {
      if (tcitydata[nodes].label === this.citydData) {
        districtdData = tcitydata[nodes].children
      }
    }
    return {
      province: this.provincedData,
      city: this.citydData,
      district: this.districtdData,
      country: '中国',
      countryData: [{ label: '中国', value: '0086' }],
      regionData: regionData,
      cityData: tcitydata,
      districtData: districtdData,
      districtRequired: undefined,
      showOption: this.option || {}
    }
  },
  watch: {
    provincedData: function (data) {
      this.province = data
      for (var node in this.regionData) {
        if (this.regionData[node].label === this.provincedData) {
          this.cityData = this.regionData[node].children
        }
      }
      for (var nodes in this.cityData) {
        if (this.cityData[nodes].label === this.citydData) {
          this.districtData = this.cityData[nodes].children
        }
      }
    },
    citydData: function (data) {
      this.city = data
    },
    districtdData: function (data) {
      this.district = data
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.regionData = regionData
    })
  },
  methods: {
    returnData: function () {
      var data = {}
      data.addrIndex = this.addrIndex
      data.country = this.country
      data.province = this.province
      data.city = this.city
      data.district = this.district
      this.$emit('changAction', data)
    },
    changeCountry: function () {
      this.province = ''
      this.city = ''
      this.district = ''
      this.returnData()
    },
    changeProvince: function () {
      for (var node in this.regionData) {
        if (this.regionData[node].label === this.province) {
          this.cityData = this.regionData[node].children
          this.districtData = []
        }
      }
      this.city = ''
      this.district = ''
      this.returnData()
    },
    changeCity: function () {
      for (var node in this.cityData) {
        if (this.cityData[node].label === this.city) {
          this.districtData = this.cityData[node].children
        }
      }
      if (this.districtData === undefined || this.districtData.length <= 0) {
        this.districtRequired = undefined
      } else {
        this.districtRequired = this.required
      }
      this.district = ''
      this.returnData()
    },
    changeDistrict: function () {
      this.returnData()
    }
  }
}
</script>

<style scoped lang="scss">
.areaSelect{
    display: flex;
    justify-content: space-between;
    :deep(*){
        height: 32px;
        line-height: 32px;
    }
    .el-select{
        width: 24%;
    }
    .inputReadonly{
      :deep(.el-input__inner){
        border: 0px;
        background: #fff;
      }
      input{
          color: #666;
          font-size: 14px;
      }
      :deep(i){
          display: none;
      }
    }
}
</style>
